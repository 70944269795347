import React, { useEffect, useRef, useState } from "react";
import * as Api from "../../../ApiV2";
import { Bookmark16Regular } from "@fluentui/react-icons";
import {
  ControlledTreeEnvironment,
  DraggingPosition,
  Tree,
  TreeInformation,
  TreeItem,
  TreeItemIndex,
  TreeItemRenderContext,
  TreeRef,
} from "react-complex-tree";
import { documentService } from "../../../service/document";
import { DataCache } from "../../../config/constant";
import {
  bookMarkState,
  dialogState,
  docuviewareMainState,
} from "../../../recoil";
import { useRecoilState } from "recoil";
import { useLang } from "../../../i18n/useLang";
import ReOrderFromBookmarkCommand from "../../../command/ReOrderBookmark-command";
import { bookMarkService } from "../../../service";
import { ContextualMenu, IContextualMenuItem } from "@fluentui/react";
import { DeleteBookmarkCommand } from "../../../command/Bookmark-command";
import { CacheLXApp } from "../../../App";
import { GoToPositon } from "../../../utils/helpers";
const BookMarkApi = new Api.BookMarkApi();
export interface TreeBookmarkProps {
  Datas: Api.BookmarkInfo[];
  onSelected: (item: Api.BookmarkInfo) => void;
  selectedItem: Api.BookmarkInfo | null;
}

const TreeBookmark = ({
  Datas,
  onSelected,
  selectedItem,
}: TreeBookmarkProps) => {
  const { tr } = useLang();
  const [ItemSelected, setSelectedItem] = useState<Api.BookmarkInfo | null>(
    null
  );
  const [bookMark, setBookMarkState] = useRecoilState(bookMarkState);
  useEffect(() => {
    setSelectedItem(selectedItem);
  }, []);
  const [itemTrees, setItemTrees] = useState<any>({
    root: {
      index: "root",
      canMove: true,
      isFolder: false,
      children: [],
      canRename: true,
      data: { id: "00000000-0000-0000-0000-000000000000" },
    },
  });
  const { getBookMarkList } = bookMarkService();
  const [selectedItems, setSelectedItems] = useState<TreeItemIndex[]>([]);
  const [firstSelected, setFirstSelected] = useState<TreeItemIndex>();
  const [focusedItem, setFocusedItem] = useState<TreeItemIndex>();
  const [expandedItems, setExpandedItems] = useState<TreeItemIndex[]>([]);
  const [showContextMenu, SetIsShowContextMenu] = useState<{
    x: number;
    y: number;
    show: boolean;
  }>({
    x: 0,
    y: 0,
    show: false,
  });
  const tree = useRef<TreeRef>(null);
  useEffect(() => {
    const timout = setTimeout(() => {
      if (tree.current) {
        tree.current.expandAll();
      }
    }, 500);
    return () => clearTimeout(timout);
  }, []);
  useEffect(() => {
    const items = [];
    for (const field in itemTrees) {
      if (field != "root" && itemTrees[field].children.length > 0) {
        items.push(field);
      }
    }
    setExpandedItems(items);
  }, [itemTrees]);
  useEffect(() => {
    setItemTrees(BuildDataTree(Datas));
  }, [Datas]);
  useEffect(() => {
    if (selectedItems.length == 1) {
      setFirstSelected(selectedItems[0]);
    }
    if (selectedItems.length == 0) {
      setFirstSelected(undefined);
    }
  }, [selectedItems]);
  const dropNodes = (items: TreeItem<any>[], target: DraggingPosition): any => {
    let ids = items.map((o) => o.index);
    if (target.targetType == "between-items") {
      const idViTriLonHons: string[] = [];
      const idViTriNhoHons: string[] = [];
      let linearIndex = target.childIndex;
      if (target.parentItem != "root") {
        linearIndex = target.childIndex;
      }
      ((itemTrees[target.parentItem].children as string[]) || []).forEach(
        (o, index) => {
          if (index < linearIndex) {
            if (!ids.includes(o)) {
              idViTriNhoHons.push(o);
            }
          } else {
            if (!ids.includes(o)) {
              idViTriLonHons.push(o);
            }
          }
        }
      );
      itemTrees[target.parentItem].children = [
        ...idViTriNhoHons,
        ...ids,
        ...idViTriLonHons,
      ];
      // }
      for (const field in itemTrees) {
        if (field != target.parentItem)
          itemTrees[field].children = (
            (itemTrees[field].children as string[]) || []
          ).filter((o) => !ids.includes(o));
      }
    } else if (target.targetType == "item") {
      // var idSelects = (Items.root.children || []).filter((o: string) =>
      //   ids.includes(o)
      // );
      const item = itemTrees[target.targetItem];

      ids = ids.filter(
        (o) => !(item.children as string[]).includes(o as string)
      );
      if (ids.length == 0) return itemTrees;
      const idUnSelects = (itemTrees.root.children || []).filter(
        (o: string) => !ids.includes(o)
      );
      itemTrees.root.children = idUnSelects;
      item.children = [...item.children, ...ids];
      for (const field in itemTrees) {
        if (field != "root" && field != target.targetItem)
          itemTrees[field].children = (
            (itemTrees[field].children as string[]) || []
          ).filter((o) => !ids.includes(o));
      }
    }
    setItemTrees({ ...itemTrees });
    return itemTrees;
  };
  function BuildDataTree(tocs: Api.BookmarkInfo[]) {
    const dataResult: any = {
      root: {
        index: "root",
        canMove: true,
        isFolder: false,
        children: tocs
          .filter((o) => o.parentId == "00000000-0000-0000-0000-000000000000")
          .map((o) => o.id),
        canRename: true,
        data: { id: "00000000-0000-0000-0000-000000000000" },
      },
    };
    tocs.forEach((element) => {
      const childs = tocs
        .filter((o) => o.parentId == element.id)
        .map((o) => o.id);
      const a = {
        index: element.id,
        name: element.title,
        canMove: true,
        isFolder: true,
        children: childs || [],
        data: element,
        canRename: true,
      };
      dataResult[element.id || ""] = a;
    });
    return { ...dataResult };
  }

  // Hàm để chuyển cây thành mảng phẳng
  function flattenTree(treeData: any, nodeId = "root") {
    const result: any[] = [];

    function traverse(nodeId: string, parentId?: string) {
      const node = treeData[nodeId];
      if (!node) return;

      // Nếu node có dữ liệu, thêm vào mảng kết quả
      if (node.data) {
        result.push({ ...node.data, id: nodeId, parentId: parentId });
      }

      // Đệ quy với từng phần tử con
      if (node.children) {
        (node.children || []).forEach((childId: string) =>
          traverse(childId, node.data.id)
        );
      }
    }
    traverse(nodeId);
    return result.filter(
      (o) => o.id != "00000000-0000-0000-0000-000000000000" && o.id != "root"
    );
  }
  const setBookMarkItemSelectedsState = (ids: string[]) => {
    const bookmarkSelected = bookMark.ListBookmark.filter((o) =>
      ids.includes(o.id || "")
    );
    setBookMarkState((cur) => {
      return {
        ...cur,
        itemSelecteds: [...bookmarkSelected],
      };
    });
  };
  return (
    <div>
      <div className="header-row">
        <div
          className="col"
          style={{
            display: "flex",
            alignItems: "center",
            flex: "1",
          }}
        >
          {tr("title")}
        </div>
        <div className="col" style={{ width: 55 }}>
          {tr("page")}
        </div>
      </div>
      <ControlledTreeEnvironment<Api.BookmarkInfo>
        canDropOnFolder
        canSearch={false}
        canDragAndDrop={true}
        canReorderItems={true}
        onDrop={(e, ta) => {
          const parentNode = e.find(
            (o) => o.data.position?.x == 0 && o.data.position?.y == 0
          );
          if (!(ta as any).targetItem || parentNode) {
            setSelectedItems([]);
            setBookMarkState((cur) => {
              return {
                ...cur,
                itemSelecteds: [],
              };
            });
            return;
          }

          const treeData = dropNodes(e, ta);
          ReOrderFromBookmarkCommand(
            bookMark.ListBookmark,
            flattenTree(treeData),
            () => {
              getBookMarkList();
            },
            () => {}
          );
        }}
        items={itemTrees}
        getItemTitle={(item) => item.data.id || ""}
        onSelectItems={(items) => {
          setSelectedItems(items);
          setBookMarkItemSelectedsState(items as string[]);
        }}
        onFocusItem={(item) => {
          setFocusedItem(item.index);
        }}
        onExpandItem={(item) =>
          setExpandedItems([...expandedItems, item.index])
        }
        onCollapseItem={(item) =>
          setExpandedItems(expandedItems.filter((o) => o !== item.index))
        }
        viewState={{
          "tree-bookmark": { selectedItems, expandedItems, focusedItem },
        }}
        renderItem={(props: {
          item: TreeItem<Api.BookmarkInfo>;
          depth: number;
          children: React.ReactNode;
          title: React.ReactNode;
          arrow: React.ReactNode;
          context: TreeItemRenderContext<never>;
          info: TreeInformation;
        }) => {
          props.context.interactiveElementProps.onDoubleClick = () => {
            const pageNo = bookMark.itemSelecteds[0]?.pageNumber || 0;
            GoToPositon(
              {
                x: bookMark.itemSelecteds[0]?.position?.x || 0,
                y: bookMark.itemSelecteds[0]?.position?.y || 0,
              },
              pageNo,
              DataCache.docuViewareID() || ""
            );
          };
          props.context.interactiveElementProps.onClick = (ev) => {
            const id = (ev.target as any).parentNode.getAttribute(
              "data-rct-item-id"
            );
            if (ev.shiftKey) {
              const flatList = flattenTree(itemTrees);
              // const lstId = flatList.map((item) => {
              //   return item.id;
              // });

              const firstSelectedItem = firstSelected || id;
              const startIndex = flatList.findIndex(
                (o) => o.id === firstSelectedItem
              );
              const endIndex = flatList.findIndex((o) => o.id === id);
              const itemsInRange = flatList.slice(
                Math.min(startIndex, endIndex),
                Math.max(startIndex, endIndex) + 1
              );
              // itemsInRange = itemsInRange.filter(
              //   (o) => o.parentId != "00000000-0000-0000-0000-000000000000"
              // );
              setSelectedItems(itemsInRange.map((o) => o.id));
              setBookMarkItemSelectedsState(itemsInRange.map((o) => o.id));
              return;
            }
            if (ev.ctrlKey) {
              // if (
              //   itemTrees[id].data.parentId ==
              //   "00000000-0000-0000-0000-000000000000"
              // )
              //   return;
              if (selectedItems.find((o) => o == id)) {
                props.context.unselectItem();
              } else {
                // const items =
                //   selectedItems.filter(
                //     (o) =>
                //       itemTrees[o].data.parentId !=
                //       "00000000-0000-0000-0000-000000000000"
                //   ) || [];
                // props.context.addToSelectedItems();
                setSelectedItems([...selectedItems, id]);
                setBookMarkItemSelectedsState([...selectedItems, id]);
              }
            } else {
              props.context.selectItem();
            }
            SetIsShowContextMenu({ x: 0, y: 0, show: false });
          };
          props.context.interactiveElementProps.onKeyUp = (ev) => {
            ev.stopPropagation();
          };
          props.context.interactiveElementProps.onKeyDown = (event) => {
            event.stopPropagation();
            (document.activeElement as any).focus();
            const inputs = document
              .querySelector(".treeToc")
              ?.querySelectorAll("input[type='text']");
            const arrElement: Element[] = [];
            inputs?.forEach((element) => {
              arrElement.push(element);
            });
            const currentIndex = arrElement.indexOf(
              document.activeElement as Element
            );
            if (event.key === "ArrowDown" || event.key === "ArrowUp") {
              if (
                event.key === "ArrowDown" &&
                currentIndex < arrElement.length - 1
              ) {
                (arrElement[currentIndex + 1] as any).focus();
                event.preventDefault(); // Prevent default arrow down behavior
              } else if (event.key === "ArrowUp" && currentIndex > 0) {
                (arrElement[currentIndex - 1] as any).focus();
                event.preventDefault(); // Prevent default arrow up behavior
              }
            }
          };
          props.context.interactiveElementProps.onDrop = (ev) => {
            document.querySelectorAll(".row").forEach((element) => {
              element.removeAttribute("style");
            });
          };
          props.context.interactiveElementProps.onFocus = () => {};
          props.context.interactiveElementProps.onContextMenu = (e) => {
            e.preventDefault();
            SetIsShowContextMenu({ x: e.clientX, y: e.clientY, show: true });
            if (selectedItems.length <= 1) {
              setSelectedItems([props.item.index]);
              setBookMarkItemSelectedsState([props.item.index as string]);
            }
          };
          // if (
          //   props.item.data.parentId == "00000000-0000-0000-0000-000000000000"
          // ) {
          //   props.context.interactiveElementProps.onDragStart = (ev) => {};
          //   props.context.interactiveElementProps.onDragEnd = (ev) => {};
          //   props.context.interactiveElementProps.onDragOver = (ev) => {};
          // }
          return (
            <li {...props.context.itemContainerWithChildrenProps}>
              <div
                {...props.context.itemContainerWithoutChildrenProps}
                {...(props.context.interactiveElementProps as any)}
                className="row"
                onDragOver={(ev) => {
                  const id = (ev.currentTarget as any).getAttribute(
                    "data-rct-item-id"
                  );
                  const item = itemTrees[id];
                  if (item.canMove) {
                    let parent = ev.currentTarget as any;
                    while ((parent != null && parent.className) != "row") {
                      parent = parent.parentElement;
                    }
                    if (parent) {
                      // Lấy tọa độ của div dropArea trong trang
                      const rect = parent.getBoundingClientRect();

                      // Tính tọa độ chuột trong div dropArea
                      const mouseX1 = ev.clientX - rect.left;
                      const mouseY1 = ev.clientY - rect.top;
                      if (mouseY1 > 7.6 && mouseY1 < 35) {
                        parent.setAttribute(
                          "style",
                          "background-color: #dbefff"
                        );
                      } else {
                        parent.removeAttribute("style");
                      }
                    }
                  }
                }}
                onDragLeave={(ev) => {
                  let parent = ev.currentTarget as any;
                  while ((parent != null && parent.className) != "row") {
                    parent = parent.parentElement;
                  }
                  parent && parent.removeAttribute("style");
                }}
                onDoubleClick={() => {
                  documentService.GotoPage(
                    DataCache.docuViewareID(),
                    props.item.data.pageNumber || 1
                  );
                }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  {props.item && (props.item.children?.length || 0) > 0
                    ? props.arrow
                    : ""}
                </div>
                <div
                  title={props.item.data.title || ""}
                  className="col"
                  style={{ flex: "1", width: "100%" }}
                >
                  <Bookmark16Regular></Bookmark16Regular> &nbsp;
                  {props.item.data.title || ""}
                </div>
                <div
                  className="col"
                  style={{ display: "flex", padding: "0 10px", width: 55 }}
                >
                  {props.item.data.pageNumber || ""}
                </div>
              </div>
              {props.children}
            </li>
          );
        }}
      >
        <Tree treeId="tree-bookmark" rootItem="root" ref={tree} />
      </ControlledTreeEnvironment>
      {showContextMenu.show && (
        <MenuContextTree
          hideMenu={() => {
            SetIsShowContextMenu({ ...showContextMenu, show: false });
          }}
          deleteItems={() => {
            setSelectedItems([]);
          }}
          targetPoint={{ x: showContextMenu.x, y: showContextMenu.y }}
        />
      )}
    </div>
  );
};
export default TreeBookmark;
const MenuContextTree = ({
  targetPoint,
  hideMenu,
  deleteItems,
}: {
  targetPoint: any;
  hideMenu: () => void;
  deleteItems: () => void;
}) => {
  const [bookMark, setBookMarkState] = useRecoilState(bookMarkState);
  const { getBookMarkList, GetAllNodesByParentId } = bookMarkService();
  const [, setDialogState] = useRecoilState(dialogState);
  const [docuviewareState, setDocuviewareMain] =
    useRecoilState(docuviewareMainState);
  useEffect(() => {
    document.addEventListener("mousedown", hideMenu);
    return () => {
      document.removeEventListener("mousedown", hideMenu);
    };
  }, []);
  const { tr } = useLang();
  const deleteItem = () => {
    if (!bookMark.itemSelecteds || bookMark.itemSelecteds.length == 0) {
      return;
    }
    // gom tất cả các node con của node hiện tại và nut hiện tại
    let oldBookmarks = [] as Api.BookmarkInfo[];
    bookMark.itemSelecteds.forEach((item) => {
      oldBookmarks = [
        ...oldBookmarks,
        ...GetAllNodesByParentId(bookMark.ListBookmark, item.id || ""),
      ];
      if (!oldBookmarks.find((o) => o.id == item.id)) {
        oldBookmarks.push(item);
      }
    });
    oldBookmarks = Array.from(
      new Map(oldBookmarks.map((item) => [item.id, item])).values()
    );
    DeleteBookmarkCommand(
      bookMark.itemSelecteds,
      oldBookmarks,
      (datas) => {
        getBookMarkList();
        documentService.RefreshPage(() => {}, DataCache.docuViewareID());
        setBookMarkState((cur) => {
          return {
            ...cur,
            itemSelecteds: [],
          };
        });
        setDocuviewareMain((cur) => {
          return {
            ...cur,
            DocuviewareInit: {
              ...cur.DocuviewareInit,
              pages: cur.DocuviewareInit?.pages?.map((o) => {
                const page = datas?.find((p) => p.id == o.id);
                if (page) {
                  return page;
                }
                return o;
              }),
            },
          };
        });
        setBookMarkState((cur) => {
          return {
            ...cur,
            itemSelecteds: [],
          };
        });
        deleteItems();
      },
      () => {}
    );
  };

  const items = [
    {
      key: "Edit",
      name: tr("edit"),
      disabled: bookMark.itemSelecteds.length > 1 ||   bookMark.itemSelecteds[0]?.parentId == "00000000-0000-0000-0000-000000000000",
      onClick: () => {
        setBookMarkState((cur) => {
          return {
            ...cur,
            ShowBookMarkModal: "edit",
          };
        });
        hideMenu();
      },
    },
    {
      key: "Delete",
      name: tr("delete"),
      onClick: () => {
        setDialogState({
          titleDialog: "",
          MsgDialog:
            tr("doYouWantToDelete") +
            ": " +
            '"' +
            bookMark.itemSelecteds.map((o) => o.title).join(",") +
            '"',
          CallBackConfirmDialog: { action: deleteItem, param: null },
          Type: "AlertDialog",
        });
        hideMenu();
      },
    },
  ] as IContextualMenuItem[];
  return <ContextualMenu target={targetPoint} items={items} />;
};
